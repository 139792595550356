import { Button } from "@mui/material/";
import Typography from "@mui/material/Typography";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Link, Navigate } from "react-router-dom";
import * as Yup from "yup";
import { showErrorMessage } from "../../actions/genericAction";
import { logoutAction, signinAction } from "../../actions/userActions";
import { REACT_APP_WP_DOMAIN } from "../../constants";
import { IRootState } from "../../interfaces/rootState";
import Loading from "../General/Loading";
import { PasswordSetField } from "../PasswordSet/PasswordSetField";
import { UNDER_MAINTENANCE } from "../../shared/constants/app.constants";
import { MARGIN_LARGE } from "../Shared/layout-constants";


const NoAccess: React.FC = (props: any) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
  function logout() {
    dispatch(logoutAction({}))
    navigate("/");
  }

    return (<>
            <div style={{ margin: MARGIN_LARGE, padding: MARGIN_LARGE}}>Your user exists but does not have access to the system, please subscribe through Apple or directly.</div>
            <Button 
            onClick={() => {
                logout();
            }}
            variant="outlined">Logout</Button>
    </>

    )
}


export default NoAccess;
