import { takeEvery, takeLatest } from "redux-saga/effects";
import { actionIds } from "../../actions/actionIds";
import { genericGet, genericPost, genericSaga } from "./genericSaga";
import solrSearchSaga from "./solrSearchSaga";

export default function* rootSaga() {
  yield takeLatest(actionIds.SOLR_SEARCH_FETCH, solrSearchSaga.solrSearchRequest);
  yield takeLatest(actionIds.CASE_LAW_SEARCH_FETCH, solrSearchSaga.caseLawSearchRequest);
  yield takeLatest(actionIds.SOLR_REGULATIONS_FETCH, solrSearchSaga.solrRegulationsRequest);
  yield takeLatest(actionIds.SOLR_QUICK_FETCH, solrSearchSaga.solrQuickRequest);
  yield takeLatest(actionIds.SOLR_RELATED_FETCH, solrSearchSaga.solrRelatedRequest);
  yield takeLatest(actionIds.USER_ACTION, solrSearchSaga.userPostRequest);
  yield takeLatest(actionIds.GET_USER_ACTION, solrSearchSaga.getUserInfoRequest);
  yield takeLatest(actionIds.UPDATE_USER_ACTION, solrSearchSaga.updateUserInfoRequest);

  yield takeLatest(actionIds.LOGOUT, (action) =>
    genericSaga.genericPassthrough(action, actionIds.LOGOUT_SUCCESS));

  yield takeLatest(actionIds.SIGNIN_ACTION, (action) =>
    genericSaga.genericHandler(action, (data: any) =>
      genericPost(data, "user/loginnew"), false, actionIds.SIGNIN_SUCCESS));

  yield takeLatest(actionIds.PASSWORD_RESET_ACTION, (action) =>
    genericSaga.genericHandler(action, (data: any) =>
      genericPost(data, "user/request_reset_password")));

  yield takeLatest(actionIds.PASSWORD_SET_ACTION, (action) =>
    genericSaga.genericHandler(action, (data: any) =>
      genericPost(data, "user/set_password")));

  yield takeLatest(actionIds.SHOW_ERROR, (action) =>
    genericSaga.showError(action));

  yield takeLatest(actionIds.UPDATE_BASIC_USER_ACTION, (action) =>
    genericSaga.genericHandler(action, (data: any) =>
      genericPost(data, "user/upsert_user_data")));

  yield takeEvery(actionIds.AI_SEARCH, (action) =>
    genericSaga.genericHandler(action, (data: any) =>
      genericPost(data, "ai/search")));

  yield takeLatest(actionIds.UPDATE_AI_FEEDBACK, (action) =>
    genericSaga.genericHandler(action, (data: any) =>
      genericPost(data, "ai/feedback")));

  yield takeLatest(actionIds.GET_DOCUMENT_REFERENCES, (action) =>
    genericSaga.genericHandler(action, (data: any) =>
      genericGet(data, "document")));

  yield takeLatest(actionIds.GET_ADMIN_DASHBOARD_DATA, (action) =>
    genericSaga.genericHandler(action, (data: any) =>
      genericGet(data, "admin/dashboard")));

  yield takeLatest(actionIds.VECTORIZE_DOCUMENT_REFERENCE, (action) =>
    genericSaga.genericHandler(action, (data: any) =>
      genericPost(data, "document/vectorize")));

  yield takeLatest(actionIds.INDEX_DOCUMENT, (action) =>
    genericSaga.genericHandler(action, (data: any) =>
      genericPost(data, "document/index")));

  yield takeLatest(actionIds.FULL_INDEX, (action) =>
    genericSaga.genericHandler(action, (data: any) =>
      genericPost(data, "admin/full_index")));

      yield takeLatest(actionIds.GET_SYSTEM_SETTINGS, (action) =>
      genericSaga.genericHandler(action, (data: any) =>
        genericGet(data, "admin/get-system-settings")));

        yield takeLatest(actionIds.UPDATE_SYSTEM_SETTINGS, (action) =>
      genericSaga.genericHandler(action, (data: any) =>
        genericPost(data, "admin/update-system-settings")));

        yield takeLatest(actionIds.REGISTER, (action) =>
          genericSaga.genericHandler(action, (data: any) =>
            genericPost(data, "user/register")));

}
