
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { IRootState } from "../../interfaces/rootState";
// import { Router, Route } from "react-router-dom";
import Footer from "../Footer/Footer";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import PrimarySearchAppBar from "../Header/Header";
import PasswordSet from "../PasswordSet/PasswordSet";
import SearchView from "../SearchView/SearchView";
import SignIn from "../Signin/Signin";
import Signup from "../Signup/Signup";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import ThankYou from "../ThankYou/ThankYou";
import UserEdit from "../UserEdit/UserEdit";
import "./App.css";
import UserTable from "../Admin/UserTable";
import AdminContainer from "../Admin/AdminContainer";
import DocumentTable from "../Admin/DocumentTable";
import { io } from 'socket.io-client';
import { useDispatch } from "react-redux";
import { mergeAIResultAction, mergeAIResultCaseLawAction } from "../../actions/genericAction";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import ResponsiveAppBar from "../Header/ReponsiveHeader";
import { Container, Typography, Box } from "@mui/material";
import AiResultTable from "../Admin/AIResultTable";
import DocumentManagement from "../Admin/DocumentManagement";
import NoAccess from "../NoAccess/NoAccess";
// import {history} from '../../config/store';

const App = () => {

  const dispatch = useDispatch();
  const error = useSelector((state: IRootState) => state.mainState.error);
  const success = useSelector((state: IRootState) => state.mainState.success);
  const loading = useSelector((state: IRootState) => state.mainState.loading);
  const socket_id = useSelector((state: IRootState) => state.mainState.socket_id);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (error && error.error) {
      enqueueSnackbar(error.error, { variant: "error" });
    }
  }, [error]);
  useEffect(() => {
    if (success && success.message) {
      enqueueSnackbar(success.message, { variant: "success" });
    }
  }, [success]);

  const loggedUser = useSelector((state: IRootState) => state.mainState.loggedUser);

  useEffect(() => {
    //console.log("socket_id change", socket_id)
    if (socket_id) {
      // Establish WebSocket connection
      const socket = io(process.env.REACT_APP_SERVER_DOMAIN || 'https://msha-server-dev.303-dev.com', {
        withCredentials: true, // Ensure cookies are sent
        query: {
          socket_id: socket_id  // Replace 'yourDataHere' with the actual data you want to send
        }
        // Additional options can be added here if needed
      });
      // Handle WebSocket events
      socket.on("connect", () => {
        console.log("WebSocket Connected", socket_id);
        // You can also send authentication data through WebSocket if needed
        socket.emit("authenticate", { socket_id: socket_id });
      });

      socket.on("userMessage", (message) => {
        //console.log("userMessage?", message);
        const context = message?.context?.toLowerCase()
        if (context === 'msha') {
          dispatch(mergeAIResultAction({ text: message.content, uuid: message.uuid }))
        }
        else if (context === 'case_law') {
          dispatch(mergeAIResultCaseLawAction({ text: message.content, uuid: message.uuid }))
        }
      });

      socket.on("disconnect", () => {
        console.log("WebSocket Disconnected");
      });

    }

  }, [socket_id]);

  const Maintenance = () => {
    return (
      <div style={{
        margin: '0', 
        height: '500px', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        backgroundColor: '#f4f4f9', 
        fontFamily: 'Arial, sans-serif'
      }}>
        <div style={{
          backgroundColor: '#fff', 
          padding: '20px', 
          borderRadius: '8px', 
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
          textAlign: 'center', 
          width: '300px', 
          height: '100px'
        }}>
          <h1 style={{ color: '#333', margin: '0', fontSize: '24px' }}>
            Under Maintenance
          </h1>
          <p style={{ color: '#666', marginTop: '10px', fontSize: '16px' }}>
            We'll be back shortly.
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
        <Router>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', margin: 0, padding: 0 }}>
        <ResponsiveAppBar loggedUser={loggedUser}/>
        <Container component="main" sx={{ mt: 3, mb: 2, flexGrow: 1, padding: '0px !important', margin: '0px !important', textAlign: 'center' }} maxWidth={false}>
            {/* Maintenance() */}
            <Routes>
              <Route path="/termsAndConditions" element={<TermsAndConditions />} />
              <Route path="/search" element={<SearchView />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/thankyou" element={<ThankYou />} />
              <Route path="/profile" element={<UserEdit />} />
              <Route path="/admin" element={<AdminContainer />}>
                <Route path="/admin/dashboard" element={<AdminDashboard />} />
                <Route path="/admin/users" element={<UserTable title={""} fixed_query_params={{}} onEditUser={function (user: any): void {
                  throw new Error("Function not implemented.");
                }} />
                } />
                <Route path="/admin/ai-results" element={<AiResultTable title={""} fixed_query_params={{}}/>
                } />
                <Route path="/admin/msha-documents" element={<DocumentTable key={"msha"} doc_type={"msha"} title={"MSHA Documents"} />
                } />
                <Route path="/admin/case-law" element={ <DocumentTable key={"case_law"} doc_type={"case_law"} title={"Case Law Documents"} />
                } />
                <Route path="/admin/document-management" element={<DocumentManagement key={"document_management"} />} />
              </Route>
              <Route path="/passwordset" element={<PasswordSet />} />
              <Route path="/forgotPassword" element={<ForgotPassword />} />
              <Route path="/noaccess" element={<NoAccess />} />
              <Route path="/" element={<SignIn />} />
            </Routes>
        </Container>
        <Box component="footer" sx={{ 
    py: 2, 
    px: { xs: 1, sm: 2 }, // Less padding on extra-small devices
    bgcolor: 'grey.200',
    width: '100%', // Ensure full width
    boxSizing: 'border-box' // Include padding in the width calculation
}}>
    <Footer />
</Box>
      </Box>
      </Router>

    </>



  );
};

export default App;
