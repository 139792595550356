import { Button, IconButton, InputAdornment } from "@mui/material/";
import Typography from "@mui/material/Typography";
import { Field, Form, Formik } from "formik";
import * as React from "react";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { registerAction } from "../../actions/userActions";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "./Signup.css";
import { SignupField } from "./SignupField";
import Loading from "../General/Loading";

const Signup: React.FC = () => {
  const [captcha, setCaptcha] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const passwordValidation = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d!@#$%^&*()_+{}":;'?/>.<,]).{8,}$/;

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(2, "Name must be at least 2 characters").required("Name is required"),
    email: Yup.string().email("Invalid email format").required("Email is required"),
    password: Yup.string()
      .matches(passwordValidation, "Password must be at least 8 characters, include 1 uppercase, 1 lowercase, and 1 number or special character")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const newUser = (values: any) => {
    dispatch(registerAction({ payload: { username: values.name, email: values.email, password: values.password, captcha } }, () => {
      navigate("/dashboard");
    }, (error) => {
      console.log("error", error);
      alert('An error occured in signup')
    }));
  };

  const onChange = (value: any) => {
    setCaptcha(value);
  };

  return (
    <>
      <Loading loading={loading} />
      <div className="SignUpContainer">
        <div className="spacing" />
        <Formik
          initialValues={{ name: "", email: "", password: "", confirmPassword: "" }}
          validationSchema={validationSchema}
          onSubmit={newUser}
        >
          {({ values, errors, touched }) => (
            <Form className="FormContainer">
              <h2>MSHAwise</h2>
              <Typography className="firstText">
                Your edge to navigating MSHA Regulations.
              </Typography>
              <Typography className="secondText">
                Create your account below. You will receive a welcome email to set
                your password in the next few minutes to get you going!
              </Typography>

              <div className="fields">
                <Field name="name" placeholder="Name" component={SignupField} />
                {touched.name && errors.name && <div className="error">{errors.name}</div>}
              </div>

              <div className="fields">
                <Field name="email" placeholder="Email" component={SignupField} />
                {touched.email && errors.email && <div className="error">{errors.email}</div>}
              </div>

              <div className="fields">
                <Field
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  component={SignupField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {touched.password && errors.password && <div className="error">{errors.password}</div>}
              </div>

              <div className="fields">
                <Field
                  name="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  component={SignupField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {touched.confirmPassword && errors.confirmPassword && (
                  <div className="error">{errors.confirmPassword}</div>
                )}
              </div>

              <div className="captcha">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
                  onChange={onChange}
                />
              </div>

              <Button
                disabled={
                  !captcha ||
                  !values.email ||
                  !values.password ||
                  values.password !== values.confirmPassword
                }
                className="signinButton"
                type="submit"
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default Signup;
