import { Button } from "@mui/material/";
import Typography from "@mui/material/Typography";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Link, Navigate } from "react-router-dom";
import * as Yup from "yup";
import { showErrorMessage } from "../../actions/genericAction";
import { signinAction } from "../../actions/userActions";
import { REACT_APP_WP_DOMAIN } from "../../constants";
import { IRootState } from "../../interfaces/rootState";
import Loading from "../General/Loading";
import { PasswordSetField } from "../PasswordSet/PasswordSetField";
import "./Signin.css";
import { SigninField } from "./SigninField";
import { UNDER_MAINTENANCE } from "../../shared/constants/app.constants";


const SignIn: React.FC = (props: any) => {
  const [changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const mainState = useSelector((state: IRootState) => state.mainState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.search === "?passwordChanged=true") {
      setChanged(true);
    }
  }, []);

//   useEffect(() => {
//     const socket = io('http://localhost:8081');

//     socket.on('connect', () => {
//         console.log('Connected to server');
//     });

//     return () => {
//         socket.disconnect();
//     };
// }, []);

  const signinUser = (values: any) => {
    setLoading(true);
    dispatch(signinAction({payload: { email: values.email, password: values.password }},
      (data) => {
        console.log("data from signin", data?.data)
        if (!data?.data?.jwt){
          navigate('/noaccess')
        }
        console.log("calling on success?");


        navigate("/search");
        setLoading(false);
      },
      (err) => {
        console.log("err", err);
        if (err?.response?.data?.message){
          dispatch(showErrorMessage(err?.response?.data?.message));
        }
        else if (err?.message === "Request failed with status code 403") {
          dispatch(showErrorMessage("User password not recognized."));
        } else {
          dispatch(showErrorMessage("Something went wrong."));
        }
        setLoading(false);
      }, "Welcome to MSHAWise",
      false,
      true
      )
    );
  };

  if (window.localStorage.jwt) {
    return <Navigate replace to="/search" />;
  }

  const checkNavigate = () => {
    if (window.localStorage.jwt) {
      return <Navigate replace to="/search" />;
    }
  };

  checkNavigate();

  return (
    <>
    <Loading loading={loading} />
    <div className="LoginContainer">

    {UNDER_MAINTENANCE && <div style={{ textAlign: "center", marginTop: 32, marginBottom: 32 }}>
    <Typography variant="h4">MSHAwise is currently undergoing maintenance.</Typography>
  </div>}

      <Formik
        initialValues={{ email: "" }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Email is invalid")
            .required("Email is required"),
        })}
        onSubmit={(values) => {
          signinUser(values);
        }}
      >
        {({ values, errors, touched }) => (
          <Form className="FormContainer">
            <h2>Sign In</h2>
            {changed ? (
              <div className="passwordReset">Your password has been successfully updated. Please sign in.</div>
            ) : null}
            <div className="fields">
              <Field name="email" placeholder="Email" component={SigninField} className={"form-control" + (errors.email && touched.email ? " is-invalid" : "")} />
            </div>
            <ErrorMessage name="email" component="div" className="invalid-feedback" />

            <div className="fields">
              <Field name="password" placeholder="Password" component={PasswordSetField} />
            </div>
            {mainState?.errorMessage?.error && (
              <div className="invalid-feedback">{mainState.errorMessage.error}</div>
            )}
            <Button className="signinButton" type="submit">Sign In</Button>
            <Typography>New to MSHAwise? <a href={`${REACT_APP_WP_DOMAIN}/pricing/`}>Wise Up</a></Typography>
            <Typography><Link to="/forgotPassword">Forgot Password?</Link></Typography>
          </Form>
        )}
      </Formik>
    </div>
    </>
  );
};

export interface Props {
  signinInfo: any;
  mainState: any;
}

export interface Values {
  firstName: string;
  lastName: string;
  email: string;
}

export interface State {
  changed: boolean;
}

export default SignIn;
