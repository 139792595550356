import SearchIcon from "@mui/icons-material/Search";
import { Box, LinearProgress } from "@mui/material";
import Button from "@mui/material/Button";
import InputBase from "@mui/material/InputBase";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";
import { actionIds } from "../../actions/actionIds";
import { caseLawSearchAction, solrQuickAction, solrRegulationsAction, solrSearchAction } from "../../actions/solrActions";
import { REACT_APP_SERVER_DOMAIN, REGULATIONS_MAP } from "../../constants";
import { IRootState } from "../../interfaces/rootState";
import Checkboxes from "../Checkboxes/Checkboxes";
import SolrResultList from "../List/SolrResultList";
import AIResponseBlock from "./AIResponseBlock";
import "./SearchView.css";
import SearchViewTabs from "./SearchViewTabs";
import { searchAIAction, updateAIFeedbackAction } from "../../actions/aiActions";
import { MARGIN_MEDIUM } from "../Shared/layout-constants";
import { clearAIResultAction, clearAIResultCaseLawAction, setAIResultAction, setAIResultCaseLawAction } from "../../actions/genericAction";
import { v4 as uuidv4 } from 'uuid';
import { UNDER_MAINTENANCE } from "../../shared/constants/app.constants";
import { safeBool } from "../../utils/general";
enum SECTION_TAB {
  REGULATIONS = 0,
  DOCUMENTS = 1,
  CASE_LAW = 2,
}

const SearchView: React.FC = () => {

  const dispatch = useDispatch();

  const mainState = useSelector((state: IRootState) => state.mainState);
  const [entityPerson, setEntityPerson] = useState([] as any[]);
  const [entityLocation, setEntityLocation] = useState([] as any[]);
  const [entityOrganization, setEntityOrganization] = useState([] as any[]);

  const [entityPersonCaseLaw, setEntityPersonCaseLaw] = useState([] as any[]);
  const [entityLocationCaseLaw, setEntityLocationCaseLaw] = useState([] as any[]);
  const [entityOrganizationCaseLaw, setEntityOrganizationCaseLaw] = useState([] as any[]);

  const [initialSearchCount, setInitialSearchCount] = useState(0);
  const [quickFactsSearchCount, setQuickFactsSearchCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchedTerm, setSearchedTerm] = useState("");
  const [documentState, setDocumentState] = useState(null as any);
  const [documentView, setDocumentView] = useState(false);
  const [miningMethod, setMiningMethod] = useState(["Surface", "Underground"]);
  const [commodity, setCommodity] = useState(["Coal", "Metal", "Nonmetal"]);

  const initialAuthorityLevel = [
    { title: "Guidance", checked: true },
    { title: "Statute", checked: true },
    { title: "Regulation", checked: true },
  ]

  const initialSubGuidance = [
    { title: "ph", checked: true },
    { title: "ppl", checked: true },
    { title: "pil", checked: true },
    { title: "ppm", checked: true },
    { title: "pib", checked: true },
    { title: "faq", checked: true },
    { title: "cg", checked: true },
    { title: "na", checked: true }
  ]

  const initialRegulationsList = {
    1: { checked: true },
    3: { checked: true },
    5: { checked: true },
    6: { checked: true },
    19: { checked: true },
    20: { checked: true },
    22: { checked: true },
    23: { checked: true },
    40: { checked: true },
    45: { checked: true },
    46: { checked: true },
    62: { checked: true },
    100: { checked: true },
    104: { checked: true },
    7: { checked: true },
    14: { checked: true },
    15: { checked: true },
    18: { checked: true },
    27: { checked: true },
    28: { checked: true },
    33: { checked: true },
    35: { checked: true },
    36: { checked: true },
    41: { checked: true },
    42: { checked: true },
    43: { checked: true },
    44: { checked: true },
    47: { checked: true },
    48: { checked: true },
    49: { checked: true },
    50: { checked: true },
    56: { checked: true },
    57: { checked: true },
    58: { checked: true },
    70: { checked: true },
    71: { checked: true },
    72: { checked: true },
    74: { checked: true },
    75: { checked: true },
    77: { checked: true },
    90: { checked: true },
  }

  const initialDocType = [
    { title: 'pdf', checked: true },
    { title: 'html', checked: true },
    { title: 'ppt', checked: true },
    { title: 'doc', checked: true }
  ]


  const [regulationsList, setRegulationsList] = useState({ ...initialRegulationsList });
  const [sort, setSort] = useState("best");
  const [numOfResults, setNumOfResults] = useState(10);
  const [offset, setOffset] = useState(0);
  const [caseLawNumOfResults, setCaseLawNumOfResults] = useState(10);
  const [caseLawOffset, setCaseLawOffset] = useState(0);
  const [documentType, setDocumentType] = useState([...initialDocType]);
  const [oneSearch, setOneSearch] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isSearchingCaselaw, setIsSearchingCaseLaw] = useState(false);
  const [regListItems, setRegListItems] = useState([] as any[]);
  const [aiResultId, setAiResultId] = useState(null as any);
  const useAI = true // safeBool(REACT_APP_USE_AI || true);
  const [aiFetching, setAiFetching] = useState(false);
  const [aiCaseLawFetching, setAiCaseLawFetching] = useState(false);
  const [feedback, setFeedback] = useState(null as any);
  const [feedbackCaseLaw, setFeedbackCaseLaw] = useState(null as any);
  const [aiResultCaseLawId, setAiResultCaseLawId] = useState(null as any);
  const [sectionTab, setSectionTab] = useState(SECTION_TAB.REGULATIONS);

  const [authorityLevel, setAuthorityLevel] = useState([...initialAuthorityLevel]);
  const [subGuidance, setSubGuidance] = useState([...initialSubGuidance]);
  const [forceSearch, setForceSearch] = useState(false);


  const socket_id = useSelector((state: IRootState) => state.mainState.socket_id);
  const aiResultState = useSelector((state: IRootState) => state.mainState.aiResult);
  const aiResultCaseLawState = useSelector((state: IRootState) => state.mainState.aiResultCaseLaw);

  const queryParams = new URLSearchParams(window.location.search);
  const useai_param = queryParams.get('useai');

  const singleDocView = (item: any, context = "documents") => {
    setDocumentState(REACT_APP_SERVER_DOMAIN + "/" + context + "/" + item.original_file_name);
    setDocumentView(true);
  };

  const backToSearch = () => {
    setDocumentView(false);
  };

  useEffect(() => {
    if (initialSearchCount !== 0) {

      if (offset > 0 || caseLawOffset > 0) {
        search(sectionTab, false);
      } else {
        // Search both docs and case law
        search(null, false);
      }

    }
  }, [sort, commodity, miningMethod, numOfResults, caseLawNumOfResults,
    offset,
    caseLawOffset,

  ]);

  useEffect(() => {
    const original_file_type = mainState?.solrResults?.facet_counts?.facet_fields?.original_file_type
    if (original_file_type) {
      const updatedDocumentType = original_file_type.map((x: any) => {
        const existingDocType = documentType.find((y: any) => x.title === y.title)

        return {
          title: x.title,
          count: x.count || 0,
          checked: existingDocType?.checked || true
        }
      })
      setDocumentType(updatedDocumentType)
    }
  }, [mainState?.solrResults?.facet_counts?.facet_fields?.original_file_type])

  useEffect(() => {
    const authority_level = mainState?.solrResults?.facet_counts?.facet_fields?.authority_level
    if (authority_level) {
      const updatedAuthorityLevel = authority_level.map((x: any) => {
        const existingAuthorityLevel = authorityLevel.find((y: any) => x.title === y.title)

        return {
          title: x.title,
          count: x.count || 0,
          checked: existingAuthorityLevel?.checked || true
        }
      })
      setAuthorityLevel(updatedAuthorityLevel)
    }

    const guidance_type = mainState?.solrResults?.facet_counts?.facet_fields?.guidance_type
    if (guidance_type) {
      const updatedGuidanceType = guidance_type.map((x: any) => {
        const existingGuidanceType = subGuidance.find((y: any) => x.title === y.title)

        return {
          title: x.title,
          count: x.count || 0,
          checked: existingGuidanceType?.checked || true
        }
      })
      setSubGuidance(updatedGuidanceType)
    }
  }, [mainState?.solrResults?.facet_counts?.facet_fields?.authority_level, mainState?.solrResults?.facet_counts?.facet_fields?.guidance_type])

  useEffect(() => {
    if (initialSearchCount !== 0) {
      if (forceSearch) {
        if (offset > 0 || caseLawOffset > 0) {
          search(sectionTab, false);
        } else {
          // Search both docs and case law
          search(null, false);
        }
      }
      setForceSearch(false)
    }
  }, [forceSearch]);

  const updateEntityResults = (entityKey: string, resultSet: any, setStateFunction: any, existingEntities: any) => {
    const results = resultSet?.[entityKey];
    if (results) {
      setStateFunction(
        results.map(({ title, count }: { title: string, count: number }) => {
          const existing = existingEntities.find((entity: any) => entity.title === title);
          return {
            title,
            count,
            checked: existing ? existing.checked : true,
          };
        })
      );
    }
  };

  useEffect(() => {
    updateEntityResults('entity_person', mainState.solrResults, setEntityPerson, entityPerson);
    updateEntityResults('entity_location', mainState.solrResults, setEntityLocation, entityLocation);
    updateEntityResults('entity_organization', mainState.solrResults, setEntityOrganization, entityOrganization);
  }, [mainState.solrResults]);

  useEffect(() => {
    updateEntityResults('entity_person', mainState.caseLawResults, setEntityPersonCaseLaw, entityPersonCaseLaw);
    updateEntityResults('entity_location', mainState.caseLawResults, setEntityLocationCaseLaw, entityLocationCaseLaw);
    updateEntityResults('entity_organization', mainState.caseLawResults, setEntityOrganizationCaseLaw, entityOrganizationCaseLaw);
  }, [mainState.caseLawResults]);

  useEffect(() => {
    if (initialSearchCount !== 0) {
      regulationsSearch();
    }
  }, [regulationsList
  ]);

  useEffect(() => {
    if (initialSearchCount !== 0) {
      initialSearch();
    }
  }, [initialSearchCount]);

  useEffect(() => {
    if (quickFactsSearchCount !== 0) {
      quickFactsSearch();
    }
  }, [quickFactsSearchCount]);

  useEffect(() => {
    const handleKeyPress = (event: any) => {
      if (event.key === "Enter" || event.keyCode === 13) {
        searchButton();
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener("keydown", handleKeyPress);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);


  // useEffect(() => {
  //   console.log("CHANGING", aiResultState)
  // }, [aiResultState]);

  const updateSort = (updated: any) => {
    setSort(updated);
  };

  const updateCommodity = (list: string[]) => {
    setCommodity(list);
  };

  const updateMining = (list: string[]) => {
    setMiningMethod(list);
  };

  const updateNumOfResults = (updated: any) => {
    if (numOfResults !== updated) {
      setNumOfResults(updated);
    }
  };

  const updateCaseLawNumOfResults = (updated: any) => {
    setCaseLawNumOfResults(updated);
  };

  const updateAuthorityLevel = (list: any) => {
    setForceSearch(true)
    setAuthorityLevel([...list]);
  };


  const updateGuidance = (list: any) => {
    setForceSearch(true)
    setSubGuidance(
      [...list]
    );
  };


  const updateDocType = (list: any) => {
    setForceSearch(true)
    setDocumentType([...list]);
  };

  const updateRegsType = (list: any) => {
    setRegulationsList({ ...list });
  };

  const updatePage = (updated: any) => {
    setOffset(updated);
  };

  const caseLawUpdatePage = (updated: any) => {
    setCaseLawOffset(updated);
  };

  const updateSearch = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const updateEntityPerson = (list: any) => {
    setForceSearch(true)
    setEntityPerson(list);
  }

  const updateEntityLocation = (list: any) => {
    setForceSearch(true)
    setEntityLocation(list);
  }

  const updateEntityOrganization = (list: any) => {
    setForceSearch(true)
    setEntityOrganization(list);
  }

  const updateEntityPersonCaseLaw = (list: any) => {
    setForceSearch(true)
    setEntityPersonCaseLaw(list);
  }

  const updateEntityLocationCaseLaw = (list: any) => {
    setForceSearch(true)
    setEntityLocationCaseLaw(list);
  }

  const updateEntityOrganizationCaseLaw = (list: any) => {
    setForceSearch(true)
    setEntityOrganizationCaseLaw(list);
  }

  const resetAllEntities = () => {
    setEntityPerson([]);
    setEntityLocation([]);
    setEntityOrganization([]);
    setEntityPersonCaseLaw([]);
    setEntityLocationCaseLaw([]);
    setEntityOrganizationCaseLaw([]);
    setAuthorityLevel([...initialAuthorityLevel])
    setSubGuidance([...initialSubGuidance]);
    setDocumentType([...initialDocType]);
    setRegulationsList({ ...initialRegulationsList });
    if (initialSearchCount > 0) {
      setForceSearch(true)
    }
  }


  const keyPress = (e: any) => {
    if (e.keyCode === 13) {
      searchButton();
    }
  };

  const reduceFilters = (filter: any) => {
    return Object.keys(filter).reduce((out: any, key: any) => {
      if (filter[key]?.checked) {
        out.push(key);
      }
      return out;
    }, []);
  };

  const reduceFiltersFacetList = (filters: any) => {
    let checkedFiltered = filters.filter((x: any) => x.checked)
    return checkedFiltered.map((x: any) => x.title);

  };

  const searchButton = () => {
    setOffset(0);
    setCaseLawOffset(0);
    setDocumentView(false);
    setInitialSearchCount(initialSearchCount + 1);
    resetAllEntities();
    // TODO how to run initial search after settings there variables.
  };

  const search = (activeTab: SECTION_TAB | null = null, isInitial: boolean = false) => {
    // IMPLEMENT use state.sectionTab
    searchCore(activeTab, isInitial);
    setSearchTerm(searchTerm);
    setOneSearch(true);
  };

  const aiSearchMSHA = () => {
    return new Promise((resolve, reject) => {
      setAiFetching(true);
      setFeedback(null);
      const uuid = uuidv4()
      setAiResultId(null)
      dispatch(clearAIResultAction({ uuid }))
      dispatch(searchAIAction({
        payload: {
          query: searchTerm,
          document_type: 'MSHA',
          socket_id: socket_id,
          uuid,
          stream: true
        }
      }, (data: any) => {
        if (data.uuid === uuid) {
          dispatch(setAIResultAction({ ...data.result, compare_uuid: uuid }));
          setAiResultId(data.aiResultId);
          setAiFetching(false);
        }
        resolve({})
      }, (e) => {
        setAiFetching(false);
        console.log("error", e);
        resolve({})
      }));
    });
  }

  const aiSearchCaseLaw = () => {
    return new Promise((resolve, reject) => {
      setAiCaseLawFetching(true);
      setFeedbackCaseLaw(null);
      const uuid = uuidv4()
      setAiResultCaseLawId(null);
      dispatch(clearAIResultCaseLawAction({ uuid }))
      dispatch(searchAIAction({
        payload: {
          query: searchTerm,
          document_type: 'CASE_LAW',
          socket_id: socket_id,
          uuid,
          stream: true
        }
      }, (data: any) => {
        if (data.uuid === uuid) {
          dispatch(setAIResultCaseLawAction({ ...data.result, compare_uuid: uuid }));
          setAiResultCaseLawId(data.aiResultId);
          setAiCaseLawFetching(false);
        }

        resolve({})
      }, (e) => {
        setAiCaseLawFetching(false);
        console.log("error case law", e);
        resolve({})
      }));
    })
  }

  function wait(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  function shouldUseAI() {
    if (useAI && useai_param !== 'false') {
      return true;
    }
    else {
      return false
    }
  }


  const initialSearch = () => {
    searchCore(null, true);
    dispatch({ type: actionIds.CLEAR_SEARCH_CACHE });
    if (shouldUseAI()) {
      aiSearchMSHA();
      aiSearchCaseLaw();
    }
    setSearchedTerm(searchTerm);
    setOneSearch(true);
    setQuickFactsSearchCount(quickFactsSearchCount + 1);
  };

  const quickFactsSearch = () => {
    dispatch(solrQuickAction({
      search: searchTerm,
    }, () => {
      regulationsSearch();
    }, () => { }) as any);
  };


  const regulationsSearch = () => {

    const regSearchParams = {
      search: searchTerm,
      filters: {
        reg_num: reduceFilters(regulationsList),
      }
    };

    dispatch(solrRegulationsAction(regSearchParams, (results: any) => {

      let rItems = results?.facet_counts?.facet_fields?.reg_num
      if (rItems.length > 0) {
        rItems = rItems.filter((x: any) => x.count > 0);
        rItems = rItems.map((x: any) => {
          return {
            title: x.title,
            label: REGULATIONS_MAP[x.title],
            count: x.count,
            checked: x.count > 0 ? true : false
          }
        })

        setRegListItems(rItems);

      }

    }, () => { }) as any);

  };

  const searchSolrCore = (isInitial = false) => {
    return new Promise((resolve, reject) => {
      let filters = {
        authority_level: reduceFiltersFacetList(authorityLevel),
        guidance_type: reduceFiltersFacetList(subGuidance),
        original_file_type: reduceFiltersFacetList(documentType),
        commodity,
        mining_method: miningMethod
      } as any

      // extract filters don't make sense across multiple searches, but can be used within a search
      if (true) {
        if (entityPerson.length) {
          const allPersonChecked = entityPerson.every((x: any) => x.checked === true);
          filters.entity_person = allPersonChecked
            ? []
            : entityPerson.filter((x: any) => x.checked === true).map((x: any) => encodeURIComponent(x.title))
          // filters.entity_person = entityPerson.filter((x: any) => x.checked === true).map((x: any) => encodeURIComponent(x.title));
          // filters.entity_person = filters.entity_person.map((x: any) => x.replace('.', '*'))
        }
        if (entityLocation.length) {
          const allLocationChecked = entityLocation.every((x: any) => x.checked === true);
          filters.entity_location = allLocationChecked
            ? []
            : entityLocation.filter((x: any) => x.checked === true).map((x: any) => encodeURIComponent(x.title))
        }
        if (entityOrganization.length) {
          const allOrganizationChecked = entityOrganization.every((x: any) => x.checked === true);
          filters.entity_organization = allOrganizationChecked
            ? []
            : entityOrganization.filter((x: any) => x.checked === true).map((x: any) => encodeURIComponent(x.title))
        }
      }

      const searchParams = {
        is_initial: isInitial,
        socket_id: socket_id,
        search: encodeURIComponent(searchTerm),
        sort,
        fuzzy: 1,
        filters,
        paging: {
          start: offset,
          rows: numOfResults
        }
      };

      dispatch(solrSearchAction(searchParams,
        (result: any) => {

          // problem is can't set it here or infinit loop, if I don't set it here, how do we set it at all, maybe a field initial values?
          // try setting state to props in initializer.,....

          setIsSearching(false);
          // setTotalSearchCount(totalSearchCount + 1);
          resolve(result);
        },
        () => { }) as any
      );
    });

  };

  const searchCaseLawCore = (isInitial = false) => {
    return new Promise((resolve, reject) => {

      let filters = {} as any;
      // extract filters don't make sense across multiple searches, but can be used within a search
      if (!isInitial) {
        if (entityPersonCaseLaw.length) {
          const allPersonChecked = entityPersonCaseLaw.every((x: any) => x.checked === true);
          filters.entity_person = allPersonChecked
            ? []
            : entityPersonCaseLaw.filter((x: any) => x.checked === true).map((x: any) => encodeURIComponent(x.title))
          // filters.entity_person = entityPersonCaseLaw.filter((x: any) => x.checked === true).map((x: any) => encodeURIComponent(x.title));
          // filters.entity_person = filters.entity_person.map((x: any) => x.replace('.', '*'))
        }
        if (entityLocationCaseLaw.length) {
          const allLocationChecked = entityLocationCaseLaw.every((x: any) => x.checked === true);
          filters.entity_location = allLocationChecked
            ? []
            : entityLocationCaseLaw.filter((x: any) => x.checked === true).map((x: any) => encodeURIComponent(x.title))
        }
        if (entityOrganizationCaseLaw.length) {
          const allOrganizationChecked = entityOrganizationCaseLaw.every((x: any) => x.checked === true);
          filters.entity_organization = allOrganizationChecked
            ? []
            : entityOrganizationCaseLaw.filter((x: any) => x.checked === true).map((x: any) => encodeURIComponent(x.title))
        }
      }

      const searchParams = {
        is_initial: isInitial,
        socket_id: socket_id,
        search: searchTerm,
        sort,
        fuzzy: 1,
        filters,
        paging: {
          start: caseLawOffset,
          rows: caseLawNumOfResults
        }
      };
      dispatch(caseLawSearchAction(searchParams, (result: any) => {
        setIsSearchingCaseLaw(false);
        resolve(result);
      }, () => { }) as any);

    });

  };

  const searchCore = (searchTab: SECTION_TAB | null = null, isInitial = false) => {


    if (isSearching || isSearchingCaselaw) {
      return;
    }
    // queue solr searches rather then parallel
    if (sectionTab === SECTION_TAB.DOCUMENTS || sectionTab === SECTION_TAB.REGULATIONS) {
      searchSolrCore(isInitial).then(() => {
        if (searchTab == null) {
          searchCaseLawCore(isInitial);
        }

      });
      setIsSearching(true);
    } else {
      searchCaseLawCore(isInitial).then(() => {
        if (searchTab == null) {
          searchSolrCore(isInitial);
        }

      });
      setIsSearchingCaseLaw(true);
    }

  };

  const sharedSearchFooterContent = () => {
    return (
      <div>
        {!documentView && (
          <Typography className="headerAndFooter">
            Our goal is to help answer questions,
            saving you time and money on MSHA Compliance.
            If something could be improved or you have your own insights to add
            <a target="_blank" href="mailto:mark@mshawise.com"> let us know! </a>
          </Typography>
        )}
      </div>
    );
  };


  let hasAccess = true;
  if (!window.localStorage.jwt){
    return <Navigate replace to="/" />;
  }
  if (!safeBool(window.localStorage.hasAccess, false)){
    hasAccess = false;
    // return <Navigate replace to="/noaccess" />
  }

  // if (!window.localStorage.cookie || !window.localStorage.expires) {
  //   window.localStorage.clear();
  //   return <Navigate replace to="/" />;
  // }
  // if (new Date() > new Date(window.localStorage.expires)) {
  //   window.localStorage.clear();
  //   return <Navigate replace to="/" />;
  // }

  const setMyState = (obj: any) => {
    for (const key in obj) {
      switch (key) {
        case "documentView":
          setDocumentView(obj[key]);
          break;
        case "document":
          setDocumentState(obj[key]);
          break;
        case "feedback":
          setFeedback(obj[key]);
          break;
        case "feedbackCaseLaw":
          setFeedbackCaseLaw(obj[key]);
      }
    }
  };

  return (<div>

    {UNDER_MAINTENANCE && <>
      <div style={{ textAlign: "center", marginTop: 32, marginBottom: 32 }}>
        <Typography variant="h4">MSHAwise is currently undergoing maintenance.</Typography>
        <Typography variant="h6">You can use the site but may not receive the best results until maintenance is complete.</Typography>
      </div>
    </>}
    {!oneSearch && (
      <Typography className="headerAndFooter">
        Welcome to the MSHAwise! You are searching across laws, regulations and all types of guidance documents and even some training materials. We are adding and updating content all the time, including our own expert compliance insights. Try searching on a word (“guardrail”), a phrase(“suspended load”), or a regulation number(“77.1104”).
      </Typography>
    )}

    <div className="SearchView">
      <div className="container">
        <div className="search">
          <div className="searchIcon">
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: "inputRoot",
              input: "inputInput",
            }}
            inputProps={{ "aria-label": "search" }}
            onChange={(e) => updateSearch(e)}
            onKeyDown={keyPress}
          />
        </div>
        <Checkboxes
          updateMining={updateMining}
          updateCommodity={updateCommodity}
        />

      </div>
    </div>
    <div style={{ marginTop: MARGIN_MEDIUM }}>
      <Button className="SearchButton" onClick={() => searchButton()}>Search</Button>
    </div>

    <div style={{ marginTop: 32 }}>
      <b>Meet the MSHA Copilot, your chatGPT powered AI dedicated to MSHA</b>
    </div>
    {shouldUseAI() && <AIResponseBlock
      //key={aiResultState?.text}
      downloadPath="/documents/"
      aiResult={aiResultState}
      aiText={aiResultState?.text}
      aiFetching={aiFetching}
      aiResultId={aiResultId}
      updateAIFeedback={(payload: any) => {
        dispatch(updateAIFeedbackAction(payload));
      }}
      feedback={feedback}
      setParentState={setMyState}
      feedbackAttributeName="feedback"
      preSearchPrompt="Run a search and MSHA AI will respond."
      aiQuotedNameString="MSHA Copilot"
    />}

    {shouldUseAI() && <AIResponseBlock
      downloadPath="/caselaw/"
      aiResult={aiResultCaseLawState}
      aiText={aiResultCaseLawState?.text}
      aiFetching={aiCaseLawFetching}
      aiResultId={aiResultCaseLawId}
      updateAIFeedback={(payload: any) => {
        dispatch(updateAIFeedbackAction(payload));
      }}
      feedback={feedbackCaseLaw}
      setParentState={setMyState}
      feedbackAttributeName="feedbackCaseLaw"
      preSearchPrompt="Run a search and MSHA CASE LAW AI will respond."
      aiQuotedNameString="MSHA Case Law Copilot"
    />}

    {(isSearching || isSearchingCaselaw) && !mainState?.solrResults?.response && !mainState.caseLawResults?.response && <div>
      <Box sx={{ width: "100%", marginTop: 2 }}>
        <LinearProgress />
      </Box>
    </div>}
    <div style={{ display: documentView ? "none" : "inherit" }}>
      {mainState?.solrResults?.response && <SearchViewTabs
        docCount={mainState.solrResults?.response?.numFound || 0}
        caseLawCount={mainState.caseLawResults?.response?.numFound || 0}
        regulationCount={mainState?.solrRegulations?.response?.numFound || 0}
        onTabChange={(event: any, newValue: any) => {
          switch (newValue) {
            case 0:
              setSectionTab(SECTION_TAB.REGULATIONS);
              break;
            case 1:
              setSectionTab(SECTION_TAB.DOCUMENTS);
              break;
            case 2:
              setSectionTab(SECTION_TAB.CASE_LAW);
              break;
          }

        }}
        currentTab={sectionTab}
        documentContent={<div>
          {
            mainState?.solrResults?.response && (
              <section className="section">
                <SolrResultList
                  resetAllEntities={resetAllEntities}
                  isSearching={isSearching}
                  context={"documents"}
                  items={mainState.solrResults.response.docs}
                  term={searchedTerm}
                  facets={mainState.solrResults.facet_counts.facet_fields}
                  singleDocView={(item: any) => singleDocView(item, "documents")}
                  updateAuthorityLevel={updateAuthorityLevel}
                  updateGuidance={updateGuidance}
                  numFound={mainState.solrResults.response.numFound}
                  solrQuick={mainState.solrQuick}
                  sort={sort}
                  updateSort={updateSort}
                  numOfResults={numOfResults}
                  updateNumOfResults={updateNumOfResults}
                  updateDocType={updateDocType}
                  updateRegsType={updateRegsType}
                  updatePage={updatePage}
                  offset={offset}
                  authorityLevel={authorityLevel}
                  subGuidance={subGuidance}
                  regulations={mainState.solrRegulations}
                  regList={regListItems}
                  regsFacet={regulationsList}
                  entityPerson={entityPerson}
                  entityLocation={entityLocation}
                  entityOrganization={entityOrganization}
                  updateEntityPerson={updateEntityPerson}
                  updateEntityLocation={updateEntityLocation}
                  updateEntityOrganization={updateEntityOrganization}
                  originalDocType={documentType}
                />
              </section>
            )}
          {sharedSearchFooterContent()}
        </div>}
        regulationsContent={<div>
          {
            mainState?.solrResults?.response && (
              <section className="section">
                <SolrResultList
                  resetAllEntities={resetAllEntities}
                  isSearching={isSearching}
                  context={"regulations"}
                  items={mainState.solrResults.response.docs}
                  term={searchedTerm}
                  facets={mainState.solrResults.facet_counts.facet_fields}
                  singleDocView={(item: any) => singleDocView(item, "documents")}
                  updateAuthorityLevel={updateAuthorityLevel}
                  updateGuidance={updateGuidance}
                  numFound={mainState.solrResults.response.numFound}
                  solrQuick={mainState.solrQuick}
                  sort={sort}
                  updateSort={updateSort}
                  numOfResults={numOfResults}
                  updateNumOfResults={() => { }}
                  updateDocType={updateDocType}
                  updateRegsType={updateRegsType}
                  updatePage={updatePage}
                  offset={offset}
                  authorityLevel={authorityLevel}
                  subGuidance={subGuidance}
                  regulations={mainState.solrRegulations}
                  regList={regListItems}
                  regsFacet={regulationsList}
                  entityPerson={entityPerson}
                  entityLocation={entityLocation}
                  entityOrganization={entityOrganization}
                  updateEntityPerson={updateEntityPerson}
                  updateEntityLocation={updateEntityLocation}
                  updateEntityOrganization={updateEntityOrganization}
                  originalDocType={documentType}
                />
              </section>
            )}
          {sharedSearchFooterContent()}
        </div>}
        caseLawContent={
          <div>
            {
              mainState?.caseLawResults?.response && (
                <section className="section">
                  <SolrResultList
                    resetAllEntities={resetAllEntities}
                    isSearching={isSearchingCaselaw}
                    context={"caselaw"}
                    items={mainState.caseLawResults.response.docs}
                    term={searchedTerm}
                    facets={mainState.caseLawResults.facet_counts.facet_fields}
                    singleDocView={(item: any) => singleDocView(item, "caselaw")}
                    updateAuthorityLevel={[]}
                    updateGuidance={updateGuidance}
                    numFound={mainState.caseLawResults.response.numFound}
                    solrQuick={[]}
                    sort={sort}
                    updateSort={updateSort}
                    numOfResults={caseLawNumOfResults}
                    updateNumOfResults={updateCaseLawNumOfResults}
                    updateDocType={updateDocType}
                    updateRegsType={updateRegsType}
                    updatePage={caseLawUpdatePage}
                    offset={caseLawOffset}
                    authorityLevel={[]}
                    subGuidance={subGuidance}
                    regulations={mainState.solrRegulations}
                    regList={regListItems}
                    regsFacet={regulationsList}
                    entityPerson={entityPersonCaseLaw}
                    entityLocation={entityLocationCaseLaw}
                    entityOrganization={entityOrganizationCaseLaw}
                    updateEntityPerson={updateEntityPersonCaseLaw}
                    updateEntityLocation={updateEntityLocationCaseLaw}
                    updateEntityOrganization={updateEntityOrganizationCaseLaw}
                    originalDocType={documentType}
                  />
                </section>
              )}
            {sharedSearchFooterContent()}
          </div>
        }
      />}
    </div>

    {documentView &&
      (
        <div>
          <div className="NavControls">
            <Typography noWrap className="docNavBar" onClick={backToSearch}>
              Back to Search Results
            </Typography>
            <Button className="printButton" target="_blank" href={documentState}>Print</Button>
          </div>
          <hr />
          <iframe title="PDF Viewer" className="pdfViewer" src={`${REACT_APP_SERVER_DOMAIN}/viewer/viewer.html?file=${encodeURIComponent(documentState)}#search=${searchedTerm}&phrase=true`} />
        {!hasAccess && <>Mobile check failed.</>}
        </div>
      )}

  </div>);
};

export default SearchView;
